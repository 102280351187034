import React, { FC } from 'react';
import Head from 'next/head';
import { ContentPageType } from '../../../models/content-page-type';
import { LinkType } from '../../../models/link-type';
import ContentPageDocumentTypeEnum from '../../../models/content-page-document-type-enum';
import { CategoryType } from '../../../models/category-type';

interface Props {
  readonly category?: CategoryType;
  readonly page: ContentPageType;
  readonly breadcrumbsOverride?: LinkType[];
  readonly urlOverride?: string;
}

const Meta: FC<Props> = ({
  page,
  breadcrumbsOverride,
  urlOverride,
  category,
}) => {
  const url = `https://${process.env.NEXT_PUBLIC_DOMAIN}${
    urlOverride || page.url
  }`;

  const breadcrumbs =
    breadcrumbsOverride || page.regions.header.breadcrumb || [];

  let title;
  let description;

  if (page.properties.webPageTitle) {
    title = page.properties.webPageTitle;
  } else {
    title = category?.metaTitle
      ? category?.metaTitle
      : `${breadcrumbs
          .map((breadcrumb) => breadcrumb.name)
          .filter((value, index) => index + 1 !== breadcrumbs.length)
          .join(' / ')} - ${page.properties.websiteName}`;
  }

  if (page.documentType === ContentPageDocumentTypeEnum.CategoryLandingPage) {
    description =
      category.metaDescription !== ''
        ? category.metaDescription
        : category.description;
  } else {
    description = page.properties.metaDescription;
  }

  const image =
    page.properties.openGraphImage?.url ||
    page.properties.defaultOpenGraphImage;

  const shouldIndex = page.properties.hideFromSearchEngines;

  const favIcon180 = page.properties.favicon180x180px;
  const favIcon32 = page.properties.favicon32x32px;
  const favIconSvg = page.properties.faviconSVG;

  return (
    <Head>
      {/*
       * GTM inserts elements into the head which breaks how Next
       * renders head tags. Some of the dummy elements become duplicated, but it's
       * better than having duplicated canonical and OG tags.
       *
       * There is an open bug for this issue, see https://github.com/vercel/next.js/issues/38877
       */}
       
      <meta name="dummy-element-1" key="dummy-element-1" />
      <meta name="dummy-element-2" key="dummy-element-2" />
      <meta name="dummy-element-3" key="dummy-element-3" />
      <meta name="dummy-element-4" key="dummy-element-4" />
      <meta name="dummy-element-5" key="dummy-element-5" />

      <script
        src="https://secure.imaginative-24.com/js/793919.js"
      />
      {/* End of GTM workaround */}
      <link rel="canonical" href={url} key="url" />
      <meta property="og:url" content={url} key="og-url" />
      <title key="title">{title}</title>
      <meta property="og:title" content={title} key="og-title" />
      {description && (
        <>
          <meta name="description" content={description} key="description" />
          <meta
            property="og:description"
            content={description}
            key="og-description"
          />
        </>
      )}
      {image && <meta property="og:image" content={image} key="og-image" />}
      {favIcon32 && (
        <link rel="icon" href={favIcon32} sizes="any" key="fav-icon-32" />
      )}
      {favIconSvg && (
        <link
          rel="icon"
          href={favIconSvg}
          type="image/svg+xml"
          key="fav-icon-svg"
        />
      )}
      {favIcon180 && (
        <link rel="apple-touch-icon" href={favIcon180} key="fav-icon-180" />
      )}
      <link rel="manifest" href="/manifest.webmanifest" key="manifest" />
      <meta
        name="robots"
        content={shouldIndex ? 'noindex, nofollow' : 'index, follow'}
        key="robots"
      />

      <noscript>
        <img alt="" src="https://secure.imaginative-24.com/793919.png" style={{display:"none"}}/>
      </noscript>
      {page?.analytics?.dataDomainScript && (
        <>
          <script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
            type="text/javascript"
            charSet="UTF-8"
            data-domain-script={page.analytics.dataDomainScript}
          />
          <script type="text/javascript">function OptanonWrapper() {}</script>
        </>
      )}
      {page?.analytics?.gtmHead && (
        <script dangerouslySetInnerHTML={{ __html: page.analytics.gtmHead }} />
      )}
    </Head>
  );
};

Meta.defaultProps = {
  category: null,
  breadcrumbsOverride: null,
  urlOverride: null,
};

export default Meta;
